<template>
  <div>
    <div class="text-h5 mt-12 mb-4">Questions Overview</div>
    <div class="d-flex align-center mb-4" lg="8">
      <span class="mr-4 text-subtitle-2 grey--text text--darken1">Date range</span>
      <div style="max-width: 160px">
        <v-select class="text-body-2" :items="rangesItems" item-text="label" item-value="value" v-model="range"
          hide-details="auto" outlined dense></v-select>
      </div>
      <div style="width: 250px; height: 40px; margin-left: 5px; margin-right: 5px;" v-if="range === 'custom-range'">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="savedRange"
          transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field outlined dense class="date_range" v-model="formattedDateRange" readonly v-bind="attrs"
              v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="customRange" no-title scrollable range :max="$date().format('YYYY-MM-DD')">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(customRange)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <span class="mx-4 text-subtitle-2 grey--text text--darken1">grouped by</span>
      <div style="max-width: 120px">
        <v-select class="text-body-2" :items="groupItems" item-text="label" item-value="value" v-model="groupType"
          hide-details="auto" outlined dense :width="120"></v-select>
      </div>
      <v-btn class="mx-3" @click="handleRefresh" :disabled="isValidating || disabledRefresh" :loading="isValidating" small icon color="primary">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </div>
    <skeleton-loader-vue
        v-if="[STATES.PENDING].includes(state)"
        type="rect"
        :height="62"
        animation="wave"
        rounded
        radius="4"
        class="skeleton"
        color="rgba(0,0,0,0.05)"
    />
    <template v-else-if="questions && questions.length">
      <v-card class="pa-4 mb-4" elevation="0" rounded v-for="question in questions" :key="question.id">
        <div class="d-flex flex-grow-1 py-4 align-center">
          <span class="error--text text-subtitle-1 mr-3" style="width:10px;">{{ question.isOptional ? '' : '*' }}</span>
          <div class="d-flex align-center question-type-badge">
            <v-icon small color="primary darken-1">{{ questionIcons[question.type] }}</v-icon>
          </div>
          <router-link :to="{
                    name: 'dashboard.voiceform.results.questions',
                    params: {
                        surveyId: $route.params.surveyId,
                        questionId: question.id
                    }
                }" class="text-body-2 px-4">
            {{ question.text || question.type }}
          </router-link>
        </div>
        <question-insight :question="question" :questionId="question.id" :range="range" :groupType="groupType"
          :customRange="savedRange"></question-insight>
      </v-card>
    </template>
  </div>
</template>

<script>
import { questionIcons } from '../../../../configs/questionTypes';
import QuestionInsight from './QuestionInsight.vue';

import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'

export default {
  name: 'ByQuestionInsights',
  components: {
    QuestionInsight
  },
  data() {
    return {
      questionIcons,
      groupType: 'week',
      disabledRefresh: false,
      rangesItems: [
        {
          label: 'Today',
          value: 'today'
        },
        {
          label: 'Yesterday',
          value: 'yesterday'
        },
        {
          label: 'Past 7 days',
          value: 'past-7-days'
        },
        {
          label: 'Past 30 days',
          value: 'past-30-days'
        },
        {
          label: 'Past 90 days',
          value: 'past-90-days'
        },
        {
          label: 'Past year',
          value: 'past-year'
        },
        {
          label: 'Custom range',
          value: 'custom-range'
        }
      ],
      groupItems: [
        {
          label: 'Day',
          value: 'day'
        },
        {
          label: 'Week',
          value: 'week'
        },
        {
          label: 'Month',
          value: 'month'
        },
        {
          label: 'Entire range',
          value: 'entire-range'
        }
      ],
      customRange: [this.$date().subtract(7, 'day').format('YYYY-MM-DD'), this.$date().format('YYYY-MM-DD')],
      menu: null
    }
  },
  computed: {
    // TODO: add date frames calculation here
    range:{
      get(){
        return this.$route.query.range || 'past-90-days'
      },
      set(value){
        const query = {...this.$route.query, range: value}
        if(value === 'custom-range'){
          query.custom_range = [this.$date().subtract(7, 'day').format('YYYY-MM-DD'), this.$date().format('YYYY-MM-DD')].join(',')
        }else{
          delete query.custom_range
        }
        this.$router.push({query})
      }
    },
    savedRange: {
      get(){
        return this.$route.query.custom_range ? this.$route.query.custom_range.split(',') : [this.$date().subtract(7, 'day').format('YYYY-MM-DD'), this.$date().format('YYYY-MM-DD')]
      },
      set(value){
        this.$router.push({query: {...this.$route.query, custom_range: value.join(',')}})
      }
    },
    formattedDateRange() {
      const [startDate, endDate] = this.savedRange;
      return `${startDate ? this.$date(startDate).format('MMM D, YYYY') : 'Start date'} to ${endDate ? this.$date(endDate).format('MMM D, YYYY') : 'End date'}`;
    }
  },
  methods: {
    handleRefresh(){
      this.disabledRefresh = true;
      setTimeout(() => {
        this.disabledRefresh = false;
      }, 5000);
      this.revalidateResults()
    }
  },
  setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () =>{
                const queryParams = new URLSearchParams();

                queryParams.set('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone)
                if(route.query.range){
                    queryParams.set("range", route.query.range);
                }

                if(route.query.custom_range){
                    const datesStrings = route.query.custom_range.split(',')

                    datesStrings[0] && queryParams.set("start_date", datesStrings[0]);
                    datesStrings[0] && queryParams.set("end_date", datesStrings[1] || datesStrings[0]);
                }
              return `/api/insights/${route.params.surveyId}/questions?${queryParams.toString()}`
            },
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            questions: data,
            error,
            isValidating,
            revalidateResults: mutate,
        }
    }
}
</script>

<style lang="scss" scoped>
.question-type-badge {
  width: 32px;
  height: 32px;
  min-width: 32px;
  justify-content: center;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;

  background-color: var(--v-primary-lighten5);
  color: var(--v-primary-darken1);
}
.skeleton{
    width: 100% !important;
}
</style>
