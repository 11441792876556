<template>
    <div 
        class="flex-grow-1 messages-list px-5" 
        ref="messagesList"
    >
        <div 
            v-for="(message, index) in messages" 
            class="d-flex flex-column py-3" 
            :key="message.id" 
            style="width: 100%;"
        >
            <message :message="message" :nextMessage="messages[index+1] || null" />
        </div>
    </div>
</template>

<script>
import Message from './Message.vue';
import { mapGetters } from 'vuex';

export default {
    components: { Message },
    computed: {
        ...mapGetters({
            messages: 'chatAgent/messages',
        }),
    },
    watch: {
        messages() {
            // Scroll to the bottom whenever messages are updated
            this.scrollToBottom();
        },
    },
    mounted() {
        // Scroll to the bottom initially
        this.scrollToBottom();
    },
    methods: {
        scrollToBottom() {
            setTimeout(() => {
                const messagesList = this.$refs.messagesList;
                if (messagesList) {
                    messagesList.scrollTop = messagesList.scrollHeight;
                }
            }, 100);
            
        },
    },
};
</script>

<style scoped>
.messages-list {
    overflow-y: auto;
    height: 100%; /* Ensure it occupies the full height of its container */
}
</style>