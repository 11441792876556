<template>
    <div class="d-flex">

        <v-avatar color="grey lighten-5" class="mr-2" size="30" >
            <img src="https://cdn.voiceform.com/media/nova-vf-logo.svg" alt="Nova">
        </v-avatar>

        <div v-if="message.content" class="pt-1">
            <vue-markdown :source="message.content"></vue-markdown>
        </div>

        <div v-if="toolCalls && toolCalls.length>0" class="d-flex pt-1">
            <v-chip
                v-for="(toolCall, index) in toolCalls"
                :key="index"
                class="mr-2 mb-1"
                label
                color="primary lighten-5"
                text-color="primary darken-1"
                small
            >
                <v-icon small left>
                    mdi-tools
                </v-icon>
                {{getToolLabel(toolCall.name)}}
            </v-chip>
        </div>

    </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
    computed:{
        toolCalls(){
            if(this.message.tool_calls){
                return this.message.tool_calls;
            }
            return []
        }
    },
    methods:{
        getToolLabel(toolName){
            if(toolName === 'summarize_data_tool'){
                return 'Summarizing Data';
            }
            if(toolName === 'retrieve_data'){
                return 'Retrieving Data';
            }

        }
    },
    components: {
        VueMarkdown
    },
}
</script>