import {NET_PROMOTER_SCORE, VOICE_QUESTION, VOICE_RESPONSE} from '../configs/questionTypes'

export const NPS_BORDER_COLORS = [
    '#621a16',
    '#922820',
    '#c3352b',
    '#E31B0C',
    '#F88078',
    '#f77b72',
    '#faa19b',
    '#fb9f1e',
    '#FFC107',
    '#357a37',
    '#2e692f'
]

export const NPS_BACKGROUND_COLORS = [
    '#d0b8b6',
    '#dfbeba',
    '#eec2be',
    '#fabdb9',
    '#fed8d6',
    '#fdd7d4',
    '#ffe2e0',
    '#ffe1bf',
    '#ffecbf',
    '#c1d6c0',
    '#bfd1bf',
]

export const SENTIMENT_BORDER_COLORS = [
    '#357a37',
    '#c3352b',
    '#757575',
]

export const SENTIMENT_BACKGROUND_COLORS = [
    '#bfd1bf',
    '#eec2be',
    '#eeeeee',
]




export const DEFAULT_BACKGROUND_COLORS = [
    '#b7d0ff',
    '#d0b2fa',
    '#f5bafe',
    '#ffbbcb',
    '#c6f7d6',
    '#ffcbbb',
    '#ffecbf',
    '#c6f7ff',
    '#ffbec5',
    '#c2cbff',
    '#fff9c1',
    '#ffcbca',
    '#c7f8e8',
    '#c8d1ff',
    '#ffddbc',
    '#c1e6ff',
]

export const DEFAULT_BORDER_COLORS= [
    '#0066FF',
    '#6200EA',
    '#D500F9',
    '#F50057',
    '#00E676',
    '#fd5312',
    '#FFC400',
    '#00E5FF',
    '#FF1744',
    '#3D5AFE',
    '#FFEA00',
    '#FF5252',
    '#1DE9B6',
    '#536DFE',
    '#FF9100',
    '#00B0FF',
]





export const getBackgroundColor = (index, questionType) => {
    if(questionType === NET_PROMOTER_SCORE){
        return NPS_BACKGROUND_COLORS[index%NPS_BACKGROUND_COLORS.length]
    }

    if([VOICE_QUESTION, VOICE_RESPONSE].includes(questionType)){
        return SENTIMENT_BACKGROUND_COLORS[index%SENTIMENT_BACKGROUND_COLORS.length]
    }

    return  DEFAULT_BACKGROUND_COLORS[index%DEFAULT_BACKGROUND_COLORS.length]

}

export const getBorderColor = (index, questionType) => {
    if(questionType === NET_PROMOTER_SCORE){
        return NPS_BORDER_COLORS[index%NPS_BORDER_COLORS.length]
    }

    if([VOICE_QUESTION, VOICE_RESPONSE].includes(questionType)){
        return SENTIMENT_BORDER_COLORS[index%SENTIMENT_BORDER_COLORS.length]
    }

    return  DEFAULT_BORDER_COLORS[index%DEFAULT_BORDER_COLORS.length]

}