<template>
    <v-container class="px-5" style="max-width: 768px">
        <div class="input-card grey lighten-3 px-3 py-2 rounded-xl">
            <div class="d-flex align-end">
                <v-textarea
                    v-model="message"
                    placeholder="Message Nova"
                    solo
                    flat
                    hide-details="auto"
                    dense
                    class="mr-2 message-input"
                    color="secondary"
                    background-color="transparent"
                    no-resize
                    auto-grow
                    rows="1"
                    ref="messageInput"
                    @keydown.enter="handleKeydown"
                ></v-textarea>
                <div class="sticky-button-container">
                    <v-fab-transition>
                        <v-btn
                            :key="chatInProgress ? 'stop' : 'send'"
                            :disabled="disableButton"
                            filled
                            @click="handleSendMessage"
                            :color="chatInProgress ? 'primary' : 'secondary'"
                            fab
                            width="38"
                            height="38"
                        >
                            <v-icon>{{ chatInProgress ? 'mdi-stop' : 'mdi-arrow-up' }}</v-icon>
                        </v-btn>
                    </v-fab-transition>
                </div>
            </div>
        </div>
    </v-container>
</template>


<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            userMessage: 'chatAgent/userMessage',
            chatInProgress: 'chatAgent/chatInProgress',
        }),
        message: {
            get() {
                return this.userMessage;
            },
            set(value) {
                this.setUserMessage(value);
            },
        },
        disableButton() {
            if (this.chatInProgress) {
                return false;
            }
            return !this.message.trim();
        },
    },
    methods: {
        ...mapMutations({
            setUserMessage: 'chatAgent/setUserMessage',
        }),
        ...mapActions({
            sendMessageAndStartStreamingRun: 'chatAgent/sendMessageAndStartStreamingRun',
            cancelRun: 'chatAgent/cancelRun',
        }),
        handleSendMessage() {
            if(this.chatInProgress){
                return this.cancelRun();
            }
            if (!this.chatInProgress && this.message.trim()) {
                this.sendMessageAndStartStreamingRun(this.userMessage);
                this.message = '';
            }
        },
        handleKeydown(event) {
            if(this.$refs.messageInput && this.$refs.messageInput.isFocused){
                if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
                    event.preventDefault(); // Prevent new line
                    this.handleSendMessage();
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.input-card{
    max-height: 30vh;
    overflow: auto;
    overflow-y: scroll;
}
.message-input::v-deep .v-input {
    border: 0 !important;
}

.message-input::v-deep textarea{
    margin: 0 !important;
    padding: 0 !important;
    padding-top: 6px !important;
}

.sticky-button-container{
    position: sticky;
    bottom: 0;
}
</style>