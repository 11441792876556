<template>
    <human-message v-if="message.type === 'human'" :message="message" />
    <ai-message v-else-if="['AIMessageChunk', 'ai'].includes(message.type)" :message="message" />
    <div v-else-if="message.type!=='tool'" class="mb-2">
        <strong>{{ message.type }}:</strong> {{ message.content }}
    </div>
</template>

<script>
import HumanMessage from './HumanMessage.vue'
import AiMessage from './AIMessage.vue'

export default {
    components: { HumanMessage, AiMessage },
    props: {
        message: {
            type: Object,
            required: true,
        },
        nextMessage: {
            type: Object,
            default: null,
        },
    },
}
</script>