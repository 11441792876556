<template>
    <div class="py-5  px-3 threads-panel">
      <div v-for="(threads, group) in groupedThreads" :key="group" class="pb-4">
        <div class="px-1 text-caption grey--text text--darken-1 pb-2">
          <b>{{ group }}</b>
        </div>
        <thread-item 
          v-for="thread in threads" 
          :key="thread.thread_id" 
          :thread="thread"
          :showTimeOnly="group === 'Yesterday' || group === 'Today' || group.endsWith('days ago')"
        />
      </div>
    </div>
  </template>

<script>
import ThreadItem from './ThreadItem.vue';
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
  components: {
    ThreadItem,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      threads: 'chatAgent/threads',
      fetchingThreads: 'chatAgent/fetchingThreads',
    }),

    groupedThreads() {
      const now = dayjs(); // Get the current time in the user's local timezone
      const groups = {};

      this.threads.forEach((thread) => {
        const threadDate = dayjs(thread.created_at); // Parse thread's created_at timestamp
        let groupLabel;

        if (threadDate.isSame(now, 'day')) {
          // Check if the thread was created today
          groupLabel = 'Today';
        } else if (threadDate.isSame(now.subtract(1, 'day'), 'day')) {
          // Check if the thread was created yesterday
          groupLabel = 'Yesterday';
        } else if (threadDate.isAfter(now.subtract(6, 'day'), 'day')) {
          // Threads within the last week
          const daysAgo = now.diff(threadDate, 'day');
          groupLabel = `${daysAgo} days ago`;
        } else if (threadDate.isAfter(now.subtract(13, 'day'), 'day')) {
          groupLabel = 'Last week';
        } else if (threadDate.isAfter(now.subtract(20, 'day'), 'day')) {
          groupLabel = '2 weeks ago';
        } else if (threadDate.isAfter(now.subtract(27, 'day'), 'day')) {
          groupLabel = '3 weeks ago';
        } else if (threadDate.isAfter(now.subtract(34, 'day'), 'day')) {
          groupLabel = '4 weeks ago';
        } else {
          groupLabel = 'Last month';
        }

        // Add the thread to the corresponding group
        if (!groups[groupLabel]) {
          groups[groupLabel] = [];
        }
        groups[groupLabel].push(thread);
      });

      return groups;
    },
  },
  methods: {
    ...mapActions({
      getThreads: 'chatAgent/getThreads',
    }),
  },
  mounted() {
    this.getThreads();
  },
};
</script>

<style lang="scss" scoped>
.threads-header{
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: var(--v-grey-lighten4);
}
.threads-panel{
    flex-grow: 0;
    width: 250px;
    min-width: 250px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--v-grey-lighten4);
}
.thread-group-header {
  font-weight: bold;
  margin-top: 1rem;
  color: var(--v-grey-darken3);
}
.thread-group {
  margin-bottom: 1rem;
}
</style>