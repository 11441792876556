<template>
    <div class="d-flex justify-end">
        <v-card elevation="0" max-width="70%" color="grey lighten-3" class="px-3 py-2 rounded-lg">
            {{ message.content }}
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'HumanMessage',
    props: {
        message: {
            type: Object,
            required: true,
        },
    },
}
</script>

