<template>
    <div class="d-flex flex-wrap">
        <div v-for="item in overviewItems" :key="item.text" class="overview-card d-flex flex-column pa-3 mr-4 mt-6">
            <div class="text-caption pb-2">{{item.text}}</div>
            <div class="text-h5">{{item.value}}</div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['survey'],
    computed: {
        overviewItems(){
            return [
                {text: 'Unique Views', value: this.views},
                {text: 'Unique Starts', value: this.starts},
                {text: 'Submissions', value: this.submissions},
                {text: 'Completion rate', value: this.completion},
                {text: 'Time to complete', value: this.completionTime},
            ]
        },
        views(){
            return this.survey && this.survey.views_count || 0
        },
        starts(){
            return this.survey && this.survey.starts_count || 0
        },
        submissions(){
            return this.survey && this.survey.results_count || 0
        },
        completion(){
            if(this.survey && this.survey.starts_count && this.survey.results_count){
               const completionRate = Number((this.survey.results_count/this.survey.starts_count*100).toFixed(2)) || 0
               if(completionRate>100){
                   return '--'
               }

               return completionRate+'%'
            }
            return '0%'
        },
        completionTime(){
            let time = '--:--';
            if (this.survey) {
                const duration = this.survey.average_time;
                const m = Math.floor(duration % 3600 / 60).toString().padStart(2, '0');
                const s = Math.floor(duration % 3600 % 60).toString().padStart(2, '0');
                time = m + ':' + s;
            }
            return time
        },
    },
}

</script>

<style lang="scss" scoped>
.overview-card{
    color: var(--v-primary-darken1);
    border: 1px solid var(--v-primary-lighten1);
    border-radius: 4px;
    min-width: 80px;
}
.overlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex-grow: 1;
    justify-content: center;
    background: linear-gradient(0deg, white, transparent);
}
</style>