<template>
    <v-autocomplete
        :items="filters"
        placeholder="Select data scope"
        height="30"
        dense
        hide-details="auto"
        v-model="scope"
        @update:search-input="handleSearchInput"
        solo
        flat
        background-color="grey lighten-4"
        style="max-width: 300px;"
        ref="dataScopeSelector"
    >
        <template v-slot:item="{item, on, attrs}">
            <data-scope-selector-item v-bind="attrs" v-on="on" :item="item"></data-scope-selector-item>
        </template>
        <template v-slot:selection="{item}">
            <data-scope-selector-item :item="item" hideCollectionContext></data-scope-selector-item>
        </template>
        <!-- <template v-slot:prepend-item>
            <v-list-item
                ripple
                @click="handleCreateNewCustomFilter"
            >
                <v-list-item-content>
                    <v-list-item-title
                        class="text-body-2"
                    >Create New Advance Filter</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template> -->
        <template v-slot:no-data>
            <div class="text-caption px-4 pa-2">
                No matching options.
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import BuilderApi from '@/api/BuilderApi';
// use this one in autosuggest for question filters
import QuestionItem from '../create/logic/QuestionItem.vue';
import DataScopeSelectorItem from './DataScopeSelectorItem.vue';
import { mapActions, mapGetters } from 'vuex';
// types: results, answers
export default {
    components: { QuestionItem, DataScopeSelectorItem },
    data(){
        return {
            searchInput: '',
            filters: [
                {
                    text: 'All Survey Responses',
                    value: null,
                    collection: 'results'
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            currentThread: 'chatAgent/currentThread',
        }),
        scope: {
            get(){
                return this.currentThread?.metadata?.data_scope || null;
            },
            set(value){
                if(this.$refs.dataScopeSelector){
                    this.$refs.dataScopeSelector.blur();
                }
                this.updateThread({
                    threadId: this.currentThread.thread_id,
                    payload: {
                        metadata: {
                            ...this.currentThread.metadata,
                            data_scope: value
                        }
                    }
                });
            }
        }
    },
    methods: {
        ...mapActions({
            updateThread: 'chatAgent/updateThread',
        }),
        handleSearchInput(value) {
            this.searchInput = value;
        },
        async loadQuestionsIntoFilters(){
            const res = await BuilderApi.listQuestions(this.$route.params.surveyId);
            if(res.data){

                // filter questions with type voice-response and map questions
                const openEndQuestionFilters = res.data.reduce((acc, q) => {
                    if (q.type === 'voice-response') {
                        acc.push({
                            text: q.text,
                            value: q.id,
                            data: q,
                            collection: 'answers'
                        });
                    }
                    return acc;
                }, []);

                this.filters = [
                    ...this.filters,
                    ...openEndQuestionFilters
                ];
            }
        },
    },

    mounted(){
        this.loadQuestionsIntoFilters();
    }
}
</script>