<template>

    <skeleton-loader-vue
        v-if="[STATES.PENDING].includes(state)"
        type="rect"
        :height="62"
        animation="wave"
        rounded
        radius="4"
        class="skeleton"
        color="rgba(0,0,0,0.05)"
    />
    <v-data-table
        v-else-if="drops"
        class="table"
        :headers="headers"
        :items="drops"
        :items-per-page="-1"
        hide-default-footer
    >
        <template v-slot:[`item.dropOff`]="{ item }">
            <div class="text-body-1" style="min-width: 100px" v-if="isNaN(item.dropOffRate) || item.dropOffRate<0 || item.dropOff===0">0</div>
            <div :class="['text-body-1 pa-2', {'highlight': item.dropOffRate>20}]" style="min-width: 100px" v-else>
                <b>{{item.dropOff}}</b> {{` (${item.dropOffRate}%)`}}
            </div>
        </template>
        <template v-slot:[`item.views`]="{ item }">
            <div class="text-body-1" style="min-width: 100px">{{item.views}}</div>
        </template>
        <template v-slot:[`item.text`]="{ item }">
            <div class="d-flex flex-grow-1 py-4 align-center">
                <span class="error--text text-subtitle-1 mr-3" style="width:10px;">{{item.isOptional?'':'*'}}</span>
                <div class="d-flex align-center question-type-badge">
                    <v-icon small color="primary darken-1" >{{ questionIcons[item.type] }}</v-icon>
                </div>
                <router-link :to="{
                    name: 'dashboard.voiceform.results.questions',
                    params: {
                        surveyId: $route.params.surveyId,
                        questionId: item.id
                    }
                }" class="text-body-2 flex-grow-1 px-4">
                    {{item.text || item.type}}
                </router-link>
            </div>
        </template>
    </v-data-table>
    <no-questions v-else></no-questions>
</template>

<script>
import { questionIcons } from '../../../../configs/questionTypes';
import useSWRV from 'swrv';
import { useRoute } from 'vue-router/composables';
import fetcher from '@/api/fetcher';
import useSwrvState from '@/utils/useSwrvState'
import NoQuestions from './NoQuestions.vue';

export default {
    data(){
        return {
            headers: [
                {
                    text: 'Question',
                    align: 'start',
                    sortable: false,
                    value: 'text',
                },
                { text: 'Views', value: 'views', width: '100px', align: 'end' },
                { text: 'Drop-off', value: 'dropOff', width: '100px', align: 'end' },
            ],
            questionIcons
        }
    },
    components: {
        NoQuestions
    },
    setup(){
        const route = useRoute();
        const { data, error, isValidating, mutate } = useSWRV(
            () => `/api/insights/${route.params.surveyId}/questions-drop-offs`,
            fetcher
        )
        const { state, STATES } = useSwrvState(data, error, isValidating)

        return {
            state, STATES,
            drops: data,
            error,
            isValidating,
            revalidateDrops: mutate,
        }
    }
}
</script>

<style lang="scss" scoped>
.table{
    flex: 1;
    background-color: transparent;
}
.question-type-badge{
    width: 32px;
    height: 32px;
    min-width: 32px;
    justify-content: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 4px;

    background-color: var(--v-primary-lighten5);
    color: var(--v-primary-darken1);
}
.highlight{
    background-color: var(--v-error-lighten4);
    border-radius: 4px;
}
.skeleton{
    width: 100% !important;
}
</style>