<template>
    <v-card class="d-flex" color="#FEFEFE" elevation="0" style="height: 100%; border-radius: 0px;">
        <threads-list></threads-list>
        <div class="d-flex flex-column flex-grow-1">
            <chat-header></chat-header>
            <messages-list></messages-list>
            <chat-input></chat-input>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MessagesList from './MessagesList.vue';
import ThreadsList from './ThreadsList.vue';
import Vue from "vue";
import ChatInput from './ChatInput.vue';
import ChatHeader from './ChatHeader.vue';

export default {
  components: { MessagesList, ThreadsList, ChatInput, ChatHeader },
    data() {
        return {
            userMessage: '',
            currentThreadId: Vue.observable({ value: null }),
            assistantId: null,
        };
    },
    provide() {
        return {
            currentThreadId: this.currentThreadId,
            assistantId: this.assistantId,
        };
    },
    
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    methods: {
        ...mapActions({
            initChatForSurveyId: 'chatAgent/initChatForSurveyId',
            createNewThread: 'chatAgent/createNewThread',
        }),
    },
    mounted() {
        this.initChatForSurveyId(this.$route.params.surveyId);
    }
}
</script>

<style lang="scss" scoped>
</style>