<template>
    <div class="d-flex pa-3">
        <data-scope-selector></data-scope-selector>
        <v-spacer></v-spacer>
        <v-btn @click="createNewThread" icon elevation="0">
            <v-icon>mdi-chat-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import DataScopeSelector from './DataScopeSelector.vue';


export default {
  components: { DataScopeSelector },
    methods: {
        ...mapActions({
            createNewThread: 'chatAgent/createNewThread',
        }),
    }
}
</script>