<template>
    <v-row class="main-layout" v-if="showAgent">
        <v-col md="6">
            <div class="px-4" style="height: 100%; overflow-y: auto;">
                <div class="pt-5"></div>
                <!-- slot for default content -->
                <slot></slot>
            </div>
        </v-col>
        <!-- slot for agent -->
        <v-col class="sticky-right-side pa-0" md="6">
            <slot name="agent"></slot>
        </v-col>
    </v-row>
    <v-container v-else>
        <slot></slot>
    </v-container>
</template>

<script>
    export default {
        name: 'InsightsAIAgentLayout',
        props: {
            showAgent: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

<style lang="scss" scoped>
.main-layout{
    height: calc(100vh - 116px);
    overflow: auto;
    margin: 0;
    padding: 0;
}
.sticky-right-side{
    position: sticky;
    top: 0px;
    height: 100%;
    overflow-y: auto;
}
</style>