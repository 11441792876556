<template>
    <div style="max-width: 300px;" class="py-2 pr-3">
        <template v-if="type === 'all'">
            <div class="d-flex align-center">
                <div class="raw-icon mr-2" :style="{ backgroundColor: surveyIconBackground }">
                    <v-icon size="16" :color="surveyIconColor">
                        $form
                    </v-icon>
                </div>
                <div class="text-body-2 survey-name">{{ survey.name }}</div>
            </div>
            <div v-if="!hideCollectionContext" class="text-caption grey--text pt-1">All responses</div>
        </template>

        <template v-else-if="type === 'open_end'">
                <question-item :question="item.data"></question-item>
                <div v-if="!hideCollectionContext" class="text-caption grey--text pt-1">All answers</div>
        </template>
            
    </div>
</template>

<script>
import QuestionItem from '../create/logic/QuestionItem.vue'
export default {
    components: { QuestionItem },
    name: 'DataScopeSelectorItem',
    inject: ['survey'],
    props: {
        item: {
            type: Object,
            required: true,
        },
        hideCollectionContext: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        surveyIconColor(){
            return this.survey?.style?.background || '#000000'
        },
        surveyIconBackground(){
            return this.survey?.style?.color || '#ffffff'
        },
        type(){
            if(this.item.value === null){
                return 'all'
            }

            if(this.item.data.type === 'voice-response'){
                return 'open_end'
            }

            if(this.item.advanced_filter){
                return 'advanced_filter'
            }
            return null
        }
    },
    methods: {
        handleSelect(){
            this.$emit('select', this.item);
        },
        getCoverBackground() {
            return this.survey?.style?.background || '#ffffff'
        },
        getCoverColor() {
            return this.survey?.style?.background || '#000000'
        },
    },
}
</script>

<style scoped>
.raw-icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.survey-name {
    white-space: nowrap;       /* Prevent text wrapping */
    overflow: hidden;          /* Hide overflow content */
    text-overflow: ellipsis;   /* Add ellipsis (...) */
    flex-grow: 1;              /* Allow the text to grow and shrink dynamically */
    min-width: 0;              /* Prevent flexbox issues with overflowing content */
}
</style>