<template>
    <div>
        <v-card v-if="[DATE_PICKER, NAME, EMAIL, ZIP_CODE, PHONE_NUMBER, CUSTOM_FIELD, FILE_UPLOAD, MATRIX, NUMERIC_INPUT].includes(question.type)" outlined
            class="d-flex pa-4 align-center flex-grow-1 justify-center text-body-2 grey--text text--darken2" rounded
            color="grey lighten-5">
            <v-btn elevation="0" :to="{
                name: 'dashboard.voiceform.results.questions',
                params: {
                    questionId: question.id,
                    surveyId: $route.params.surveyId,
                }
            }" outlined color="secondary lighten-2">View Answers</v-btn>
        </v-card>
        <Bar v-else-if="question.answers && question.answers.length" :chart-options="chartOptions" :chart-data="data"
            :width="width" :height="height" />
        <v-card v-else outlined class="d-flex pa-4 align-center justify-center text-body-2 grey--text text--darken2"
            rounded color="grey lighten-5">
            <span>{{ question.type === DESCRIPTION_SLIDE ? 'Not applicable' : 'No answers' }}</span>
        </v-card>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'
import { getBackgroundColor, getBorderColor } from '../../../../utils/chartColors'
import {
    CHECKBOX,
    NET_PROMOTER_SCORE,
    YESNO,
    DESCRIPTION_SLIDE,
    DATE_PICKER, NAME,
    EMAIL,
    PHONE_NUMBER,
    CUSTOM_FIELD,
    VOICE_QUESTION,
    VOICE_RESPONSE,
    ZIP_CODE,
    FILE_UPLOAD,
    MATRIX,
    NUMERIC_INPUT,
} from '../../../../configs/questionTypes'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'QuestionInsight',
    props: {
        question: {
            type: Object,
            required: true
        },
        range: {
            type: String,
            required: true
        },
        customRange: {
            type: Array,
            required: false
        },
        groupType: {
            type: String,
            required: true
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 200
        },
    },
    data() {
        return {
            DESCRIPTION_SLIDE, DATE_PICKER, NAME, EMAIL, PHONE_NUMBER, ZIP_CODE, CUSTOM_FIELD, FILE_UPLOAD, MATRIX, NUMERIC_INPUT
        }
    },
    components: {
        Bar
    },
    methods: {
        getTimeFrameKey(currentTimeFrame) {
            if (this.groupType === 'entire-range') {
                return this.rangeLabel;
            }
            if (this.groupType === 'week') {
                return `${currentTimeFrame.format('MMM')} ${currentTimeFrame.format('D')} - ${currentTimeFrame.endOf('week').format('D')}, ${currentTimeFrame.format('YYYY')}`
            }
            return currentTimeFrame.format(this.timeFrameFormat)
        }
    },
    computed: {
        data() {
            const datasets = Object.entries(this.answersData).map((entry, index) => ({
                label: entry[0],
                backgroundColor: [getBackgroundColor(index, this.question.type)],
                borderColor: [getBorderColor(index, this.question.type)],
                borderWidth: 2,
                borderRadius: 4,
                data: Object.entries(entry[1]).map(dateEntry => ({ x: dateEntry[0], y: dateEntry[1] })),
            }))
            return {
                datasets
            }
        },
        timeFrameFormat() {
            let timeFrameFormat = 'MMM D, YYYY'
            if (this.groupType === 'week') {
                timeFrameFormat = 'ddd MMM D, YYYY'
            }
            if (this.groupType === 'month') {
                timeFrameFormat = 'MMM, YYYY'
            }
            if (this.groupType === 'entire-range') {
                timeFrameFormat = 'Range'
            }

            return timeFrameFormat
        },
        rangeStartDate() {
            let startingDate = this.$date().startOf('day')
            if (this.range === 'yesterday') {
                return startingDate.subtract(1, 'day')
            }
            if (this.range === 'past-7-days') {
                return startingDate.subtract(7, 'day')
            }
            if (this.range === 'past-30-days') {
                return startingDate.subtract(30, 'day')
            }
            if (this.range === 'past-90-days') {
                return startingDate.subtract(90, 'day')
            }
            if (this.range === 'past-year') {
                return startingDate.subtract(1, 'year')
            }
            if (this.range === 'custom-range') {
                const [startDate] = this.customRange;
                if (startDate) {
                    return this.$date(startDate);
                }
            }
            return startingDate;
        },
        rangeLabel() {
            if (this.range === 'yesterday') {
                return `${this.$date().subtract(1, 'day').format('MMM D, YYYY')} to ${this.$date().format('MMM D, YYYY')}`;
            }
            if (this.range === 'past-7-days') {
                return `${this.$date().subtract(7, 'day').format('MMM D, YYYY')} to ${this.$date().format('MMM D, YYYY')}`;
            }
            if (this.range === 'past-30-days') {
                return `${this.$date().subtract(30, 'day').format('MMM D, YYYY')} to ${this.$date().format('MMM D, YYYY')}`;
            }
            if (this.range === 'past-90-days') {
                return `${this.$date().subtract(90, 'day').format('MMM D, YYYY')} to ${this.$date().format('MMM D, YYYY')}`;
            }
            if (this.range === 'past-year') {
                return `${this.$date().subtract(1, 'year').format('MMM D, YYYY')} to ${this.$date().format('MMM D, YYYY')}`;
            }
            if (this.range === 'custom-range') {
                const [startDate, endDate] = this.customRange;
                return `${this.$date(startDate).format('MMM D, YYYY')} to ${this.$date(endDate).format('MMM D, YYYY')}`;

            }
            return this.$date().format('MMM D, YYYY');
        },
        timeFrames() {
            if (this.groupType === 'entire-range') {
                return { [this.rangeLabel]: 0 };
            }
            const framesObj = {};
            let today = this.$date().startOf('day');
            let currentTimeFrame = this.rangeStartDate.startOf(this.groupType)

            while (today.startOf(this.groupType).diff(currentTimeFrame, this.groupType) >= 0) {
                const dateKey = this.getTimeFrameKey(currentTimeFrame)
                framesObj[dateKey] = 0
                currentTimeFrame = currentTimeFrame.add(1, this.groupType)
            }
            return framesObj
        },
        answersData() {
            const answers = this.question.answers;

            const data = {}

            this.question.multiple_choice_items?.forEach(item => {
                data[item.value || item.order] = { ...this.timeFrames }
            })

            if (this.question.type === NET_PROMOTER_SCORE) {
                for (let index = 0; index < 11; index++) {
                    data[`${index}`] = { ...this.timeFrames }
                }
            }

            if ([VOICE_QUESTION, VOICE_RESPONSE].includes(this.question.type)) {
                data['positive'] = { ...this.timeFrames }
                data['negative'] = { ...this.timeFrames }
                data['neutral'] = { ...this.timeFrames }
            }


            // yesno question type doesn't use multiplechoise field
            if (this.question.type === YESNO) {
                data['yes'] = { ...this.timeFrames }
                data['no'] = { ...this.timeFrames }
            }


            // the checkbox result value is an array so we should loop through it too
            if (this.question.type === CHECKBOX) {
                answers.forEach(ans => {
                    if(ans.value){
                        const choices = ans.value.value
                        choices && choices.map(choice => {
                            const dateKey = this.getTimeFrameKey(this.$date(ans.created_at).startOf(this.groupType))
                            if(data[choice]){
                                if (data[choice][dateKey]) {
                                    data[choice][dateKey]++
                                } else {
                                    data[choice][dateKey] = 1
                                }
                            }
                        })
                    }
                })
            } else if ([VOICE_QUESTION, VOICE_RESPONSE].includes(this.question.type)) {
                answers.forEach(ans => {
                    const sentiment = ans.sentiment
                    if (sentiment) {
                        const dateKey = this.getTimeFrameKey(this.$date(ans.created_at).startOf(this.groupType))
                        if (data[sentiment][dateKey]) {
                            data[sentiment][dateKey]++
                        } else {
                            data[sentiment][dateKey] = 1
                        }
                    }
                })
            } else {
                answers.forEach(ans => {
                    if(ans.value){
                        const dateKey = this.getTimeFrameKey(this.$date(ans.created_at).startOf(this.groupType));
                        if(data[ans.value.value]){
                            if (data[ans.value.value][dateKey]) {
                                data[ans.value.value][dateKey]++
                            } else {
                                data[ans.value.value][dateKey] = 1
                            }
                        }
                    }
                })
            }
            return data

        },
        chartOptions() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                            maxRotation: 0,
                        },
                        grid: {
                            display: false,
                        },
                        stacked: true,
                        beginAtZero: true,
                    },
                    y: {
                        beginAtZero: true,
                        stacked: true,
                        title: {
                            display: true,
                            text: 'Total answers'
                        },
                        ticks: {
                            precision: 0
                        }
                    }
                },

            }
        }
    },
}
</script>