var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ([_vm.STATES.PENDING].includes(_vm.state))?_c('skeleton-loader-vue',{staticClass:"skeleton",attrs:{"type":"rect","height":62,"animation":"wave","rounded":"","radius":"4","color":"rgba(0,0,0,0.05)"}}):(_vm.drops)?_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.drops,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.dropOff",fn:function(ref){
var item = ref.item;
return [(isNaN(item.dropOffRate) || item.dropOffRate<0 || item.dropOff===0)?_c('div',{staticClass:"text-body-1",staticStyle:{"min-width":"100px"}},[_vm._v("0")]):_c('div',{class:['text-body-1 pa-2', {'highlight': item.dropOffRate>20}],staticStyle:{"min-width":"100px"}},[_c('b',[_vm._v(_vm._s(item.dropOff))]),_vm._v(" "+_vm._s((" (" + (item.dropOffRate) + "%)"))+" ")])]}},{key:"item.views",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-1",staticStyle:{"min-width":"100px"}},[_vm._v(_vm._s(item.views))])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-grow-1 py-4 align-center"},[_c('span',{staticClass:"error--text text-subtitle-1 mr-3",staticStyle:{"width":"10px"}},[_vm._v(_vm._s(item.isOptional?'':'*'))]),_c('div',{staticClass:"d-flex align-center question-type-badge"},[_c('v-icon',{attrs:{"small":"","color":"primary darken-1"}},[_vm._v(_vm._s(_vm.questionIcons[item.type]))])],1),_c('router-link',{staticClass:"text-body-2 flex-grow-1 px-4",attrs:{"to":{
                name: 'dashboard.voiceform.results.questions',
                params: {
                    surveyId: _vm.$route.params.surveyId,
                    questionId: item.id
                }
            }}},[_vm._v(" "+_vm._s(item.text || item.type)+" ")])],1)]}}],null,true)}):_c('no-questions')}
var staticRenderFns = []

export { render, staticRenderFns }