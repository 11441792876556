<template>
    <v-hover v-slot="{ hover }">
        <v-card elevation="0" @click="handleClick" :color="hover?'grey lighten-2':color" class="thread-item pa-1 mb-2">
            {{ threadName }}
            <div v-if="hover" class="more-menu-button">
                <v-spacer></v-spacer>
                <v-btn @click.stop="handleDelete" icon small>
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-hover>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
export default {
    props:{
        thread:{
            type: Object,
            required: true,
        },
        showTimeOnly:{
            type: Boolean,
            default: false,
        }
    },
    computed:{
        ...mapGetters({
            currentThreadId: 'chatAgent/currentThreadId',
        }),
        isSelected(){
            return this.currentThreadId === this.thread.thread_id;
        },
        color(){
            return this.isSelected ? 'grey lighten-2' : 'transparent';
        },
        threadName(){
            if(this.showTimeOnly){
                return `Chat at ${this.$date(this.thread.created_at).format('h:mm A')}`;
            }
            // short version of date without year and time
            return `Chat on ${this.$date(this.thread.created_at).format('MMM D [at] h:mm A')}`;
        }
    },
    methods:{
        ...mapActions({
            switchThread: 'chatAgent/switchThread',
            deleteThread: 'chatAgent/deleteThread',
        }),
        handleClick(){
            this.switchThread(this.thread);
        },
        handleDelete(){
            this.deleteThread(this.thread.thread_id);
        }
    }
}
</script>

<style lang="scss" scoped>
.thread-item{
    position: relative;
    font-size: 1.6rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.more-menu-button{
    display: flex;
    justify-items: flex-end;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // gradient from right side to left side
    background: linear-gradient(to right, transparent, var(--v-grey-lighten4) 90%);
}
</style>